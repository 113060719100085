"use client";

import { useCallback, useEffect, useState } from "react";
import { Menu, X } from "react-feather";
import { Logo } from "../Logo";
import { Size } from "@/constants/enums/size";
import Link from "next/link";
import classNames from "classnames";
import { useAuth } from "hooks/authentication";
import { usePathname } from "next/navigation";

interface MenuItems {
	center: { name: string; link: string; type?: string }[];
	right: { name: string; link: string; type?: string }[];
}

export const NavigationBar = () => {
	const [showMenu, setShowMenu] = useState(false);
	const [scrolledToTop, setScrolledToTop] = useState(true);

	const pathname = usePathname();

	const showTransparentNavbar = pathname === "/landing";

	const { user } = useAuth();

	const [menuItems, setMenuitems] = useState<MenuItems>({
		center: [
			{ name: "Blogs", link: "/blogs" },
			{ name: "Partners", link: "/partners" },
			{ name: "Locations", link: "/locations" },
		],
		right: [
			{
				name: "Login",
				link: "/login",
			},
			{
				name: "Sign Up",
				link: "/register",
			},
		],
	});

	useEffect(() => {
		if (user) {
			setMenuitems({
				center: [
					{ name: "Blogs", link: "/blogs" },
					{ name: "Partners", link: "/partners" },
					{ name: "Locations", link: "/locations" },
				],
				right: [{ name: `Hey, ${user.username}`, link: "/settings" }],
			});
		} else {
			setMenuitems({
				center: [
					{ name: "Blogs", link: "/blogs" },
					{ name: "Partners", link: "/partners" },
					{ name: "Locations", link: "/locations" },
				],
				right: [
					{
						name: "Login",
						link: "/login",
						type: "basic",
					},
					{
						name: "Sign Up",
						link: "/register",
						type: "cta",
					},
				],
			});
		}
	}, [user]);

	const handleScroll = useCallback(() => {
		if (window.scrollY > 0) setScrolledToTop(false);
		else setScrolledToTop(true);
	}, []);

	useEffect(() => {
		document.addEventListener("scroll", handleScroll);
		return () => {
			document.removeEventListener("scroll", handleScroll);
		};
	}, [handleScroll]);

	const renderItem = ({
		name,
		link,
		type,
	}: {
		name: string;
		link: string;
		type?: string;
	}) => {
		return (
			<Link
				className={classNames("navbar__item", {
					"navbar__item--cta": type === "cta",
					"navbar__item--basic": type === "basic",
				})}
				key={name}
				href={link}
			>
				<h3>{name}</h3>
			</Link>
		);
	};

	// Responsive menu
	if (showMenu)
		return (
			<div className="navbar-open">
				<div className="close" onClick={() => setShowMenu(false)}>
					<X />
				</div>

				<div className="navbar-open__content">
					{renderItem({ name: "Home", link: "/" })}
					{[...menuItems.center, ...menuItems.right].map((item) =>
						renderItem(item)
					)}
				</div>
			</div>
		);

	return (
		<>
			<div className={classNames("navbar navbar--transparent-bg", {})}>
				<div
					className={classNames("navbar__default", {
						"navbar__default--scrolled-to-top": scrolledToTop,
					})}
				>
					<div className="container">
						<div className="navbar__side">
							<Link className="logo" href="/">
								<Logo size={Size.SMALL} withText />
							</Link>
						</div>

						<div className="navbar__center flex-center">
							<div className="navbar__items">
								{menuItems.center?.map((item) => renderItem(item))}
							</div>
						</div>

						<div className="navbar__side flex-end">
							<button className="login__button">
								<div className="navbar__items">
									{menuItems.right?.map((item) => renderItem(item))}
								</div>
							</button>
						</div>
					</div>
				</div>
			</div>

			<div
				className={classNames("navbar-responsive", {
					"navbar-responsive--transparent": showTransparentNavbar,
				})}
			>
				<div className="navbar-responsive__icon" />

				<div className="logo">
					<Link href="/">
						<Logo size={Size.MEDIUM} />
					</Link>
				</div>

				<div className="navbar-responsive__icon">
					<button onClick={() => setShowMenu(!showMenu)}>
						<Menu />
					</button>
				</div>
			</div>
		</>
	);
};
